import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import {
  Heading,
  Paragraph,
  Pane,
  Image,
  TextInput,
  Button,
  toaster,
} from '@muhsis/ui'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'

import { theme } from '../theme'
import { Layout, SEO, BackgroundImageGrid } from '../components'
import { HeaderAuth } from '../blocks'
import { imgMuhsisWeb } from '../assets/images'
import { sendMembershipRequest } from '../services'

const SignupSchema = Yup.object().shape({
  email: Yup.string()
    .email('Geçersiz email.')
    .required('Bu alan boş bırakılamaz.'),
})

const MockupContainer = styled(Pane)``

// Replace this with validation error element from @muhsis/ui
const ScErrorMessage = styled.span`
  display: block;
  color: ${theme.palette.red.base};
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  margin-top: 0.25rem;
`

const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <Fragment>
    <TextInput
      name="email"
      id="email"
      marginRight={5}
      width={250}
      height={38}
      {...field}
      {...props}
    />
    {touched[field.name] && errors[field.name] && (
      <ScErrorMessage>{errors[field.name]}</ScErrorMessage>
    )}
  </Fragment>
)

class RegisterPage extends Component {
  state = {
    email: '',
    isLoading: false,
  }

  async handleFormSubmit(values, actions) {
    this.setState({ isLoading: true })
    try {
      const result = await sendMembershipRequest(values.email)
      this.setState({ isLoading: false, email: '' })
      actions.resetForm({ email: '' })

      // Continue if there's no api rate limiting
      if (result.data.StatusMessageType === 'Success') {
        toaster.success('Başarılı!', {
          description: `Muhsis'e üye olabilmeniz için size bir e-posta gönderdik. E-postadaki linke tıklayarak üyeliğinizi tamamlayabilir ve Muhsis'i hemen kullanmaya başlayabilirsiniz.`,
          duration: 10,
        })
      } else {
        toaster.danger('Hata!', {
          description: result.data.Message[0],
          duration: 10,
        })
      }
    } catch (err) {
      if (err) {
        toaster.warning('Çok Fazla İstek!', {
          description:
            'Kısa süre içerisinde çok fazla istekte bulundunuz. İstekte bulunmadan önce lütfen bir süre bekleyiniz.',
          duration: 10,
        })
        this.setState({ isLoading: false, email: '' })
        actions.resetForm({ email: '' })
      }
    }
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Üye Ol"
          keywords={[
            `web tabanlı muhasebe üye ol`,
            `web tabanlı ön muhasebe kullan`,
            `muhasebe programı`,
            `web muhasebe programı kullan`,
            `online muhasebe programı üye ol`,
          ]}
        />

        <BackgroundImageGrid>
          <Pane height="100vh" display="flex" flexDirection="column">
            <Pane>
              <HeaderAuth />
            </Pane>

            <Pane flex="1" display="flex" alignItems="center">
              <Pane width="100%" overflow="hidden">
                <Grid fluid>
                  <Row>
                    <Col sm={6}>
                      <Pane
                        maxWidth={400}
                        height="100%"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <Heading size={700} marginBottom="0.5rem">
                          Ücretsiz Deneme Üyeliğinizi Hemen Başlatın
                        </Heading>
                        <Paragraph marginBottom="1rem">
                          Üye olun, ekibinizi Muhsis'e davet edin ve firmanızın
                          finansal süreçlerini, sizin için hazırladığımız
                          kullanımı kolay ve hızlı arayüzler ile kolaylıkla
                          yönetin. İlk 30 gün ücretsiz.
                        </Paragraph>

                        <Formik
                          initialValues={{
                            email: this.state.email,
                          }}
                          enableReinitialize={true}
                          validationSchema={SignupSchema}
                          onSubmit={(values, actions) => {
                            this.handleFormSubmit(values, actions)
                          }}
                          render={props => (
                            <form onSubmit={props.handleSubmit}>
                              <Pane position="relative" marginBottom="0.5rem">
                                <Field
                                  name="email"
                                  type="email"
                                  placeholder="Email adresiniz.."
                                  component={CustomInputComponent}
                                  value={props.values.email}
                                  disabled={this.state.isLoading}
                                />
                              </Pane>

                              <Button
                                type="submit"
                                appearance="primary"
                                intent="success"
                                isLoading={this.state.isLoading}
                                height={38}
                                top="-1px"
                              >
                                Ücretsiz Dene
                              </Button>
                            </form>
                          )}
                        />
                      </Pane>
                    </Col>

                    <Col sm={6}>
                      <MockupContainer>
                        <Image src={imgMuhsisWeb} alt="" maxHeight={600} />
                      </MockupContainer>
                    </Col>
                  </Row>
                </Grid>
              </Pane>
            </Pane>

            <Pane padding="2rem">
              <Paragraph>
                Muhsis'e üye olarak,{' '}
                <a href="/kullanici-sozlesmesi" target="_blank">
                  Kullanıcı Sözleşmesi
                </a>{' '}
                ve{' '}
                <a href="/gizlilik-politikasi" target="_blank">
                  Gizlilik Politika
                </a>{' '}
                'mızı kabul etmiş sayılırsınız.
              </Paragraph>
            </Pane>
          </Pane>
        </BackgroundImageGrid>
      </Layout>
    )
  }
}

export default RegisterPage
